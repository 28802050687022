/*
 * @Author: wangliang
 * @Date: 2023-04-20 15:14:18
 * @LastEditTime: 2024-09-03 16:24:58
 * @LastEditors: wangliang
 * @Description: 系统信息
 */
export const systemName = "银杏大模型";
export const systemNameBottom =
	`版权所有 © 杭州叙简科技股份有限公司 `;

export const systemInfo = [
	{
		systemName: '银杏大模型',
		icon: 'favicon.ico',
		logo: <img src={require('@/assets/images/logo.png')} alt="" /> 
	},
	{
		systemName: '余小应',
		icon: 'yu_favicon.ico',
		logo: <img src={require('@/assets/images/yu_logo.png')} alt="" /> 
	},
]

